import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-order-create-view.html";
import { state, store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";
import { OrderModel, OrderStatus } from "../../models/order-model";
import { generateUniqueId } from "../../common/utility";
import { SingleStateValue } from "../../models/single-state-value-model";
import { DeliveryManagementSectionMessage } from "../dms-section-message/dms-section-message";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { inject } from "../../utility/decorators/decorators";
import { OrderService } from "../../service/order-service/order-service";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { Router } from "@vaadin/router";
import { DMSHubService } from "../../service/hub-service/hubs-service";
import { HubDataModel } from "../../models/hub-data-model";
import { DMSRidersService } from "../../service/riders-service/riders-service";
import { DMSCategoryService } from "../../service/category-service/category-service";

@customElement("dms-order-create-view")
export class DeliveryManagementOrderCreateView extends DMSInternalBaseClass {
  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;
  @inject(SERVICE_PROVIDER_ID.HUBS_SERVICE)
  hubsService: DMSHubService;
  @inject(SERVICE_PROVIDER_ID.RIDERS_SERVICE)
  ridersService: DMSRidersService;
  @inject(SERVICE_PROVIDER_ID.CATEGORY_SERVICE)
  categoryService: DMSCategoryService;

  @property({ type: Boolean })
  fetchedHubs = false;
  @property({ type: Boolean })
  fetchedRiders = false;
  @property({ type: Boolean })
  fetchedCategories = false;
  private hubData: HubDataModel[];
  private ridersData: string[];
  private orderCategories: string[];
  private originHubs: string[];
  private riderNames: string[];
  private destinationHubs: string[];
  // private orderCategories = new Array<string>(
  //   "Pharmacy Order",
  //   "Sample Order",
  //   "B2B Sample",
  //   "Consumable",
  //   "Sample Empty Order",
  //   "Marketing Material",
  //   "B2C Distributor",
  //   "B2B Distributor",
  //   "B2C Order",
  //   "B2B Pharmacy"
  // );
  private orderSizes = new Array<string>(
    "Small",
    "Medium",
    "Large",
    "Extra Large"
  );
  private dispatchMediums = new Array<string>("Bus", "Local");
  constructor() {
    super();
    if (Object.keys(state.Order).length === 0) {
      const initialOrderObject = {} as OrderModel;
      initialOrderObject.origin = {
        value: {
          hubName: "",
          stateName: "",
          cityName: "",
          regionName: "",
          hubTier: "",
        },
        isValid: false,
      };
      initialOrderObject.destination = {
        value: {
          hubName: "",
          stateName: "",
          cityName: "",
          regionName: "",
          hubTier: "",
        },
        isValid: false,
      };
      initialOrderObject.dispatchMedium = this.returnStateObject();
      initialOrderObject.orderId = "";
      initialOrderObject.orderSize = this.returnStateObject();
      initialOrderObject.riderName = this.returnStateObject();
      initialOrderObject.pickupRemark = this.returnStateObject("", true);
      initialOrderObject.orderCategory = this.returnStateObject();
      initialOrderObject.orderStatus = OrderStatus.NONE;
      initialOrderObject.orderCreationTime = "";
      initialOrderObject.orderImage = {
        value: "" as unknown as File,
        isValid: false,
      };
      initialOrderObject.pickupDate = this.returnStateObject();
      initialOrderObject.pickupTime = this.returnStateObject();
      store.dispatch(orderActions.upsertOrder(initialOrderObject));
      this.eventBus.subscribe(
        "orderImage-file-dispatched",
        this._orderImageEventHandler
      );
    }
  }

  private recievedImage: any;
  private _orderImageEventHandler = this.orderImageEventHandler.bind(this);

  private orderImageEventHandler(e: any) {
    this.recievedImage = e.detail.file;
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    await this.fetchHubs();
    await this.fetchRiders();
    await this.fetchCategories();
  }

  private async fetchHubs() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.hubsService.getHubsData();
      this.hubData = response?.hubs;
      const hubNames: string[] = this.hubData?.map((obj: any) => obj.hubName);
      this.originHubs = this.destinationHubs = hubNames;
      this.fetchedHubs = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private async fetchRiders() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.ridersService.getRidersData();
      this.ridersData = response?.riders;
      const riderNames: string[] = this.ridersData?.map(
        (obj: any) => obj.riderName
      );
      this.riderNames = riderNames;
      this.fetchedRiders = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }
  private async fetchCategories() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.categoryService.getCategoryData();
      const categoryData = response?.categories;
      const categoryNames: string[] = categoryData?.map(
        (obj: any) => obj.orderCategory
      );
      this.orderCategories = categoryNames;
      this.fetchedCategories = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private async handleCreateOrder() {
    this.updateStaticStates();
    this.setErrorAndScroll();
    if (this.checkAllValid(store.getState()?.Order)) {
      try {
        this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
        const response = await this.orderService.createOrder();
        try {
          await this.orderService.uploadImage(
            this.recievedImage,
            response.orderId,
            "pickup"
          );
        } catch (error) {
          console.log('Image Upload Issue')
        }
        finally{
          Router.go(`/order-detail/${response.id}`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.eventBus.publish(SHOW_HIDE_SPINNER, {
          detail: { visible: false },
        });
      }
    }
  }

  private checkAllValid(obj: any): boolean {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        obj[key].hasOwnProperty("isValid") &&
        !obj[key].isValid
      ) {
        return false;
      }
    }
    return true;
  }

  private setErrorAndScroll() {
    const isValidFlagsWithKeys = Object.entries(store.getState()?.Order)
      .filter(
        ([_, prop]) =>
          prop != null && typeof prop === "object" && "isValid" in prop
      )
      .map(([key, prop]) => ({
        key,
        isValid: (prop as { isValid: boolean }).isValid,
      }));
    isValidFlagsWithKeys.forEach(({ key, isValid }) => {
      const errorElement = document.getElementById(
        `${key}-Error`
      ) as DeliveryManagementSectionMessage;
      if (errorElement) {
        errorElement.visible = !isValid;
      }
    });

    const firstErrorElement = isValidFlagsWithKeys.find(
      ({ key, isValid }) => !isValid && document.getElementById(`${key}-Error`)
    );
    if (firstErrorElement) {
      const errorElement = document.getElementById(
        `${firstErrorElement.key}-Error`
      );
      if (errorElement) {
        const yOffset = -100;
        const y =
          errorElement.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }

  private returnStateObject(value = "", isValid = false) {
    const emptyObject = <SingleStateValue>{
      value: "",
      isValid: isValid,
    };
    return emptyObject;
  }

  private updateStaticStates() {
    const orderId = generateUniqueId("Curebay");
    const currentDate = new Date().toISOString();
    const orderIdStateObject = {
      fieldName: "orderId",
      fieldValue: orderId,
    };
    const dateStateObject = {
      fieldName: "orderCreationTime",
      fieldValue: currentDate,
    };
    const orderStatusStateObject = {
      fieldName: "orderStatus",
      fieldValue: OrderStatus.CREATED,
    };
    store.dispatch(orderActions.updateOrder(orderStatusStateObject));
    store.dispatch(orderActions.updateOrder(orderIdStateObject));
    store.dispatch(orderActions.updateOrder(dateStateObject));
  }

  render() {
    return template.call(this);
  }

  shouldUpdate(): boolean {
    return this.fetchedHubs && this.fetchedRiders && this.fetchedCategories;
  }

  disconnectedCallback(): void {
    this.eventBus.unsubscribe("file-dispatched", this._orderImageEventHandler);
  }
}
